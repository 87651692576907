import React from "react"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"

import "./i18n"

const ItemListLink: React.FC<{ children: React.ReactNode; to: string }> = ({
  children,
  to,
}) => {
  return (
    <li style={{ display: "inline-block", marginLeft: "15px" }}>
      <Link to={to} style={{ color: "#9A9" }}>
        {children}
      </Link>
    </li>
  )
}

const Header: React.FC<{ title: string }> = ({ title }) => {
  const { t, i18n } = useTranslation()
  return (
    <div
      style={{
        backgroundColor: "#011002",
        paddingLeft: "10px",
        paddingRight: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div></div>
        <ul>
          <ItemListLink to="/">{t("home.title")}</ItemListLink>
          <ItemListLink to="/help">{t("help.title")}</ItemListLink>
          <ItemListLink to="/privacy-policy">{t("privPolicy.title")}</ItemListLink>
        </ul>
      </div>
      <h2>{title}</h2>
    </div>
  )
}

export default Header
