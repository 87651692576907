import React from "react"

import Header from "./Header"

import googleBadge from "../images/google-play-badge.png"
import appleBadge from "../images/apple-badge.svg"

const Layout: React.FC<{ title: string; children: React.ReactNode }> = ({
  children,
  title,
}) => {
  return (
    <div
      style={{
        margin: "auto",
        maxWidth: 800,
      }}
    >
      <Header title={title} />
      <div
        style={{
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        {children}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          paddingTop: "80px",
          paddingBottom: "80px",
        }}
      >
        <div style={{ height: 60, padding: 0 }}>
          <a href="https://play.google.com/store/apps/details?id=games.cmopp.cogitosolitaire">
            <img src={googleBadge} alt="Google Play" height="60px" />
          </a>
        </div>
        <div style={{ height: 60, padding: 10 }}>
          <a href="https://apps.apple.com/us/app/cogito-solitaire/id1482620036?ls=1">
            <img src={appleBadge} alt="App Store" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Layout
