import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import Backend from "i18next-xhr-backend"
import LanguageDetector from "i18next-browser-languagedetector"

const langDetectionOptions = {
  order: [
    "navigator",
    "querystring",
    "cookie",
    "localStorage",
    "htmlTag",
    "path",
    "subdomain"
  ],
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
      transKeepBasicHtmlNodesFor: ["strong"]
    },
    detection: langDetectionOptions
  })

export default i18n
